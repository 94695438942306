import React, { useState } from "react";
import styles from './styles/YoutubeFacade.module.scss';
import Image from "next/image";

function YouTubeFacade({ videoId }) {
    const [clicked, setClicked] = useState(false);

    return (
        <div className={styles.youtubeContainer} onClick={() => !clicked && setClicked(true)}>
            {clicked ? (
                <iframe 
                    title="YouTube video player"
                    src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
                />
            ) : (
                <Image 
                src={"/video-placeholder.png"}
                alt="fai click per vedere il video di presentazione del mate"
                layout="fill"
                objectFit="cover"
                loading="eager" 
            />
            )}
        </div>
    );
}   
  
export default YouTubeFacade;
