import React from 'react'
import styles from './styles/Preparazione.module.scss'
import Image from 'next/image'

export default function Preparazione() {
    return (

        <div className={styles.mateInfo}>
          <h1> Caratteristiche della Yerba Mate</h1>
          <ul>
            <li>
              <Image src="/origine.png" alt="Mate Verde Argentino" width={60} height={60} />
              <strong>Colore dell'infuso:</strong> Giallo ocra.</li>
            <li>
              <Image src="/aroma.png" alt="Mate Verde Argentino" width={60} height={60} />
              <strong>Aroma:</strong> Erbaceo e delicato.</li>
            <li>
              <Image src="/gusto.png" alt="Mate Verde Argentino" width={60} height={60} />
              <strong>Gusto:</strong> Con una leggera nota amarognola.</li>
            <li>
              <Image src="/quando.png" alt="Mate Verde Argentino" width={60} height={60} />
              <strong>Momento ideale per il consumo:</strong> Mattino e pomeriggio.</li>
            <li>
              <Image src="/efficacia.png" alt="Mate Verde Argentino" width={60} height={60} />
              <strong>Efficacia:</strong> Percettibile sin dalla prima tazza.</li>
            <li>
              <Image src="/agg.png" alt="Mate Verde Argentino" width={60} height={60} />
              <strong>Degustazione:</strong> Ottimo al naturale, senza aggiunte.</li>
            <li>
              <Image src="/dosi.png" alt="Mate Verde Argentino" width={60} height={60} />
              <strong>Dose consigliata:</strong> 4-5 gr per tazza (circa un cucchiaio raso).</li>
            <li>
              <Image src="/temperatura.png" alt="Mate Verde Argentino" width={60} height={60} />
              <strong>Temperatura di infusione:</strong> Ideale tra 85-90 °C.</li>
            <li>
              <Image src="/tempo.png" alt="Mate Verde Argentino" width={60} height={60} />
              <strong>Tempo di infusione:</strong> Suggerito tra 5-8 minuti.</li>
          </ul>
    
       
    
          <section className={styles.benefits}>
    <h2>Benefici</h2>
    <p>
        La magia della Yerba Mate risiede nella presenza di xantine, come caffeina, teofillina e teobromina, sostanze stimolanti anche presenti nel tè, caffè e cioccolato. Queste sostanze agiscono sul sistema nervoso centrale, migliorando la concentrazione, la memoria e l’umore. Inoltre, il Mate ha un effetto termogenico, cioè aumenta il consumo di calorie e favorisce il dimagrimento. Il Mate contiene anche vitamine del gruppo B, C ed E, che contribuiscono al benessere generale dell’organismo.
    </p>
</section>

<section className={styles.preparation}>
    <h2>Come prepararlo</h2>
    <p>
        Tradizionalmente, il Mate viene servito in un Calebasse (un tipo di zucca) e sorseggiato attraverso una Bombilla (cannuccia con filtro). Per preparare il Mate, si riempie il Calebasse per circa due terzi con le foglie essiccate e tritate della pianta. Si inclina il Calebasse e si crea una cavità sul lato opposto alle foglie. Si versa un po’ di acqua tiepida nella cavità e si lascia riposare per qualche minuto. Si inserisce la Bombilla nella cavità e si aggiunge acqua calda ma non bollente fino a riempire il Calebasse. Si beve il Mate aspirando dalla Bombilla e si aggiunge altra acqua quando il liquido si esaurisce. Il Mate si può aromatizzare con erbe, spezie, miele o zucchero a piacere.
    </p>
</section>

<section className={styles.curiosity}>
    <h2>Una curiosità storica</h2>
    <p>
        Nel XVII secolo, quando i missionari Gesuiti giunsero in Sudamerica, furono attratti dal Mate e ne iniziarono la coltivazione nelle loro riduzioni. Il Mate divenne così una fonte di reddito per i Gesuiti e una bevanda diffusa tra i coloni spagnoli. Tuttavia, il re di Spagna Carlo III proibì il commercio del Mate nel 1767, temendo che i Gesuiti avessero troppo potere economico e politico. I Gesuiti furono espulsi dalle colonie e le loro piantagioni furono distrutte o abbandonate. Il Mate rimase una bevanda popolare solo tra gli indigeni e i gauchos (i mandriani delle pampas). Solo nel XIX secolo, il Mate tornò ad essere apprezzato da tutti i ceti sociali e divenne il simbolo dell’identità culturale sudamericana.
    </p>
</section>

        </div>
      );
    }
