import styles from './styles/FAQ.module.scss';

function FAQ({ data }) {

    if (!data) return null;

  return (
    <section className={styles.container__faq}>
      <h2 className={styles.faq__title}>Domande frequenti sul Mate</h2>
      <dl className={styles.faq__list}>
        {data.map((item, index) => (
          <div key={index}>
            <dt>{item.question}</dt>
            <dd>{item.answer}</dd>
          </div>
        ))}
      </dl>
    </section>
  );
}

export default FAQ;
