import React from 'react';
import ProductData from '/public/json/product.json';
import Image from 'next/image';
import styles from './styles/Product.module.scss';
import Link from 'next/link';

export default function Products() {
    return (
        <section className={styles.product__container} aria-label="Lista de Productos">
            {ProductData.products.map((product) => (
                <article key={product.id} className={styles.product__item}>
                    <Link href={`/yerbaMate/${product.tag}`} legacyBehavior={true}>
                        <a aria-label={`Vedi ${product.name}`}>
                            <Image 
                                src={product.image} 
                                alt={`Imagine di ${product.name}`} 
                                width={400} 
                                height={200} 
                            />
                            <h3>{product.name}</h3> 
                        </a>
                    </Link>
                </article>
            ))}
        </section>
    )
}
