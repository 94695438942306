import styles from '@/styles/Home.module.scss';
import Layout from './components/Layout';
import Image from 'next/image';
import Product from './components/Products';
import FAQ from './components/FAQ';
import Bar from './components/Bar';
import Preparazione from './components/Preparazione';
import YouTubeFacade from './components/YoutubeFacade';
import useIsMobile from '@/hooks/useIsMobile';
const FAQ_DATA = [ 
  {
    question: "Da dove proviene il mate?",
    answer: "Il mate è originario del Sud America, specialmente da paesi come Uruguay e Paraguay."
  },
  {
    question: "Quali sono le proprietà del mate?",
    answer: "Il mate ha proprietà stimolanti simili a quelle del caffè e viene spesso consumato per la sua energia naturale."
  },
  {
    question: "Perché il mate è così importante nella cultura sudamericana?",
    answer: "Il mate non è solo una bevanda, ma un rituale di socializzazione e un simbolo di ospitalità."
  },
  {
    question: "Come si prepara il mate?",
    answer: "Il mate viene preparato in un contenitore chiamato mate, utilizzando foglie essiccate e acqua calda, consumandolo attraverso una bombilla."
  },
  
];export default function Home() {
  const isMobile = useIsMobile();


  const videoId = "k6Dj9FrbNaU"

  return (
<Layout 
     title="La Yerba Mate: Bevanda, Rituale e Simbolo dell'America del Sud"
      description="Esplora il fascino del mate, l'elisir sudamericano ricco di energia. Non solo una semplice bevanda, il mate rappresenta un rituale, una tradizione profonda. Delizioso come il caffè, ma con una storia e una cultura tutta sua. Scopri ora!"
      image="https://res.cloudinary.com/dbb295wbs/image/upload/v1692229791/mates/Roasted_Mate_Plant_1024x1024_2x_a53lbg.webp"
      url="https://www.matepuro.com/"
    >

      {!isMobile && (
      <aside className={styles.aside} aria-label="Contenuto secondario">
        <FAQ data={FAQ_DATA} />
        <div className={styles.container__title}>
          <h2>Le migliori tazze per il tuo mate</h2>
        </div>
        <Bar />
      </aside>
      )}

      <main className={styles.main} aria-labelledby="mainTitle">
        <div className={styles.container__banner}>
            <YouTubeFacade videoId={videoId} />
        </div>    
  
        <div className={styles.container__title}>
          <h1 id="mainTitle">Il Mate: Bevanda, Rituale e Simbolo del Sud America</h1>
        </div>

        <section className={styles.container__content}>
          <article className={styles.article}>
        
    <div className={styles.container__text}>
    <div className={styles.container__content}>
    <h2>Il significato culturale del mate in Sud America</h2>
    <p>
        Il mate non è solo una bevanda tradizionale in Sud America; rappresenta una profonda tradizione culturale, essendo al centro di molteplici rituali sociali. Questa bevanda, ricca di storia, è vista come un simbolo di condivisione, unità e ospitalità. Predominantemente consumato in paesi come Argentina, Uruguay, Paraguay, Bolivia, Cile e parti del Brasile, il mate va oltre il suo sapore, diventando un veicolo di storie, leggende e momenti di comunione tra amici e familiari.
    </p>
</div>

        <div className={styles.container__image}>
            <Image src="/mate.webp" alt="Un tradizionale recipiente per mate con bombilla" width={400} height={480} />
        </div>
            </div>

          </article>

          <article className={styles.article}>
            <h2>Scegli la tua Yerba Mate</h2>
            <Product />

            <article className={styles.article}>
    <h3>Da che piante proviene la yerba mate?</h3>
    <p>
        La pianta da cui si ottiene la yerba mate, scientificamente conosciuta come Ilex paraguariensis, è originaria dei bacini dei fiumi Paraná, Paraguay e Uruguay. Queste regioni subtropicali offrono il clima ideale per la crescita e lo sviluppo di questa pianta, che richiede condizioni specifiche di umidità e temperatura.
    </p>
    
    <div className={styles.container__image}>
    <Image src="https://res.cloudinary.com/dbb295wbs/image/upload/v1692229791/mates/Roasted_Mate_Plant_1024x1024_2x_a53lbg.webp" alt="Pianta di yerba mate" width={400} height={400} />
    </div>
    <h3>Benefici e proprietà della yerba mate per la salute</h3>
<p>
    La yerba mate, una bevanda tradizionale sudamericana, è conosciuta non solo per il suo sapore distintivo ma anche per i molteplici benefici che apporta alla salute. Oltre a stimolare la mente e favorire la digestione, il mate è ricco di antiossidanti, combatte l'invecchiamento cellulare, aiuta a regolare i livelli di colesterolo e potenzia il sistema immunitario. La sua preparazione tipica avviene utilizzando foglie di mate essiccate in un contenitore specifico chiamato "mate", e si beve attraverso una cannuccia detta "bombilla". Nonostante i suoi vantaggi, è importante moderare il consumo. Un consumo eccessivo può causare irritazione gastrica, aumento della pressione arteriosa o interagire con determinati farmaci. Pertanto, persone con condizioni specifiche come gravidanza, osteoporosi o alcolismo dovrebbero evitarlo o consultare un professionista sanitario prima di includerlo nella loro dieta quotidiana.
</p>

</article>



              <article className={styles.article}>

                <Preparazione />

              </article>

             
          </article>
        </section>
      </main>
    </Layout>
  );
}
