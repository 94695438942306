import React from 'react';
import tazze from "/public/json/tazze.json";
import styles from './styles/Bar.styles.module.scss';
import Image from 'next/image';
import Link from 'next/link';

export default function Bar() {
  return (
    <section className={styles.bar} aria-label="List of Tazze products">
      {tazze.tazze.map((tazza) => (
        <article key={tazza.id}>
          <Link 
            href={`/tazze/${tazza.slug}`} 
            title={`View details about ${tazza.productName}`} 
            aria-label={`View details about ${tazza.productName}`}
          >
            <div className={styles.bar__item}>
              <div className={styles.title}>
                <h2>{tazza.productName}</h2>
              </div>
              <Image 
                src={tazza?.image} 
                alt={`Image of ${tazza.productName}`} 
                width={200} 
                height={200}
                priority={tazza.isFeatured} 
              />
            </div>
          </Link>
        </article>
      ))}
    </section>
  );
}
